import React, { useState } from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { useCookies } from "react-cookie";
import "./Login.css";

const Login = ({ visible, toggleLogin }) => {
  const [form] = Form.useForm();
  const [cookies, setCookie] = useCookies(["user"]);
  const [loading, setLoading] = useState(false);

  const handleLogin = async () => {
    try {
      setLoading(true);
      // Simulate delay for loading indication
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Hardcoded credentials for demonstration (replace with your actual credentials)
      const hardcodedEmail = "pandeyyashika";
      const hardcodedPassword = "2001";

      // Retrieve form values
      const { email, password } = await form.validateFields();

      // Validate credentials
      if (email === hardcodedEmail && password === hardcodedPassword) {
        // Set cookie and close modal on successful login
        setCookie("user", true, { path: "/" });
        toggleLogin(true); // Enable protected routes
        window.location.href = "/"; // Reload to home page
      } else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      message.error("Invalid email username or birth year. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Login"
      visible={visible}
      footer={null} // Remove the default footer (which includes the close button)
      maskClosable={false} // Prevent closing the modal when clicking outside
      closable={false} // Hide the close button
      className="login-modal">
      <Form form={form} layout="vertical">
        <Form.Item
          label="Email Username"
          name="email"
          rules={[
            { required: true, message: "Please enter your email username !" },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Birth Year"
          name="password"
          rules={[
            { required: true, message: "Please enter your birth year!" },
          ]}>
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleLogin} loading={loading} block>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Login;

// src/components/ImageGallery.js
import React, { useState } from "react";
import { Modal } from "antd";
import "./ImageGallery.css";
import quote1 from "../assets/quote_1.jpg";
import quote2 from "../assets/quote_2.jpg";
import quote3 from "../assets/quote_3.jpg";

const images = [
  { src: quote1, alt: "My Sweet Mom" },
  { src: quote2, alt: "My Brother Unish" },
  { src: quote3, alt: "My Mamas" },
];

const ImageGallery = () => {
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const showModal = (image) => {
    setCurrentImage(image);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setCurrentImage(null);
  };

  return (
    <section className="image-gallery">
      <div className="image-grid">
        {images.map((image, index) => (
          <div
            key={index}
            className="image-item"
            onClick={() => showModal(image)}>
            <img src={image.src} alt={image.alt} />
            <div className="item-details">{image.alt}</div>
          </div>
        ))}
      </div>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        className="image-modal"
        bodyStyle={{ padding: 0 }}
        closeIcon={null} // Disable the default Ant Design close button
      >
        {currentImage && (
          <div className="modal-content">
            <img
              src={currentImage.src}
              alt={currentImage.alt}
              className="modal-image"
            />
            <span className="modal-close-icon" onClick={handleCancel}>
              &times;
            </span>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default ImageGallery;

import React from "react";
import { Typography } from "antd";
import "./Birthdays.css";
import birthdayCardImage from "./yasikabirthdaycard.jpg"; // Ensure the correct path to the image

const Birthdays = () => {
  const { Paragraph } = Typography;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = birthdayCardImage;
    link.download = "yasikabirthdaycard.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="birthdays-container">
      <div
        style={{
          borderLeft: "4px solid #E90074",
          paddingLeft: "16px",
          margin: "20px 0 40px 0",
        }}>
        <Paragraph style={{ fontSize: "20px", fontStyle: "italic" }}>
          Dear <b>Yasika</b>
        </Paragraph>
        <Paragraph
          style={{ fontSize: "20px", fontStyle: "italic" }}
          align="justify">
          Happy Birthday, Yasika! I hope your day and this upcoming year are
          filled with all the happiness and peace that you deserve. May your
          heart be light, your troubles few, and your blessings many. Also, I
          wish you all the success in the things that you are hardworking for.
          You are an amazing person from whom one can learn a lot from. Cheers
          to your health, happiness, and prosperity!
          <br />
          <br />
          Happy Birthday once again!!!.
        </Paragraph>
        <Paragraph style={{ fontSize: "20px", fontStyle: "italic" }}>
          Cordially Yours, <br /> Bigyan
        </Paragraph>
      </div>
      <h2>Birthday Card</h2>
      <a onClick={handleDownload}>
        <img
          src={birthdayCardImage}
          alt="Birthday Card"
          className="birthday-card"
        />
      </a>
    </div>
  );
};

export default Birthdays;

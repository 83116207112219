import React, { useEffect } from "react";
import "./Home.css";

const Home = () => {
  useEffect(() => {
    const nameElement = document.querySelector(".name");
    const name = "Yasika Pandey";
    let index = 0;

    function type() {
      if (index < name.length) {
        nameElement.innerHTML += name[index];
        index++;
        setTimeout(type, 100); // Adjust typing speed here
      }
    }

    type();
  }, []);

  return (
    <div className="home-container">
      <section className="intro-section">
        <div className="intro-text">
          <h4>Hi, I'm</h4>
          <h1>
            <span className="name"></span>
          </h1>
        </div>
      </section>
    </div>
  );
};

export default Home;

// src/components/Quotes.js
import React from "react";
import ImageGallery from "./ImageGallery";
import "./Quotes.css"; // Import additional CSS for styling

const Quotes = () => {
  return (
    <div className="quotes-container">
      <div className="quotes-heading">
        <h1 className="quotes-heading-text">Quotes</h1>
        <div className="quotes-heading-line"></div>
      </div>
      <ImageGallery />
    </div>
  );
};

export default Quotes;

import React, { useState, useEffect } from "react";
import { Layout, Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./App.css";
import Home from "./components/Home";
import AboutMe from "./components/AboutMe";
import Quotes from "./components/Quotes";
import Birthdays from "./components/Birthdays";
import AppFooter from "./components/Footer";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Celebration from "./components/Celebration"; // Import the Celebration component

const { Header, Footer, Content } = Layout;

function App() {
  const [visible, setVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const [loginModalVisible, setLoginModalVisible] = useState(!cookies.user);
  const [showCelebration, setShowCelebration] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  let navigate = useNavigate();

  const handleMenuClick = (path, key) => {
    setActiveMenu(key);
    navigate(path);
    onClose();
  };

  const handleLogoClick = () => {
    setActiveMenu("");
    navigate("/");
    onClose();
  };

  const handleLoginToggle = (isLoggedIn) => {
    setLoginModalVisible(!isLoggedIn);
  };

  useEffect(() => {
    const today = new Date();
    if (
      today.getDate() === 11 &&
      today.getMonth() === 6 &&
      today.getFullYear() === 2024
    ) {
      setShowCelebration(true);
    }
  }, []);

  return (
    <div
      className={`app-container ${
        (showCelebration && cookies.user ? "celebration-modal-visible" : "") ||
        (loginModalVisible ? "login-modal-visible" : "")
      }`}>
      <div className="app-overlay"></div>
      <Layout className="layout">
        <Header className="header">
          <div className="logo">
            <Link to="/" onClick={handleLogoClick} className="logo-link">
              Yasika Pandey
            </Link>
          </div>
          <div className="desktop-menu">
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[activeMenu]}
              style={{ marginLeft: "auto" }}>
              <Menu.Item key="1" onClick={() => handleMenuClick("/about", "1")}>
                <Link to="/about">About Me</Link>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => handleMenuClick("/quotes", "2")}>
                <Link to="/quotes">Quotes</Link>
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => handleMenuClick("/birthdays", "3")}>
                <Link to="/birthdays">Birthdays</Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className="mobile-menu">
            <Button
              type="primary"
              icon={<MenuOutlined />}
              onClick={showDrawer}
            />
          </div>
          <Drawer
            title="Menu"
            placement="right"
            onClose={onClose}
            visible={visible}>
            <Menu theme="light" mode="inline" selectedKeys={[activeMenu]}>
              <Menu.Item key="1" onClick={() => handleMenuClick("/about", "1")}>
                <Link to="/about" onClick={onClose}>
                  About Me
                </Link>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => handleMenuClick("/quotes", "2")}>
                <Link to="/quotes" onClick={onClose}>
                  Quotes
                </Link>
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => handleMenuClick("/birthdays", "3")}>
                <Link to="/birthdays" onClick={onClose}>
                  Birthdays
                </Link>
              </Menu.Item>
            </Menu>
          </Drawer>
        </Header>
        <Content className="content">
          <Routes>
            <Route
              path="/about"
              element={
                <PrivateRoute>
                  <AboutMe />
                </PrivateRoute>
              }
            />
            <Route
              path="/quotes"
              element={
                <PrivateRoute>
                  <Quotes />
                </PrivateRoute>
              }
            />
            <Route
              path="/birthdays"
              element={
                <PrivateRoute>
                  <Birthdays />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
          </Routes>
          <Login visible={loginModalVisible} toggleLogin={handleLoginToggle} />

          {/* Celebration Modal */}
          {cookies.user && (
            <Celebration
              visible={showCelebration}
              onClose={() => setShowCelebration(false)}
            />
          )}
        </Content>
        <Footer className="footer">
          <AppFooter />
        </Footer>
      </Layout>
    </div>
  );
}

export default App;

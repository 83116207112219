// src/components/Celebration.js
import React from "react";
import { Modal } from "antd";
import "./Celebration.css";
import CandleAnimation from "./CandleAnimation";

const Celebration = ({ visible, onClose }) => (
  <Modal
    visible={visible}
    onCancel={onClose}
    footer={null}
    className="celebration-modal"
    closable={true}
    width={600} // Adjust width prop as needed
  >
    <div className="celebration-content">
      <CandleAnimation />
    </div>
  </Modal>
);

export default Celebration;

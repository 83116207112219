// src/components/Footer.js

import React from "react";
import { Layout } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import "./Footer.css";

const { Footer } = Layout;

const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Footer className="footer">
      <div className="footer-content">
        <div className="social-links">
          <a
            href="https://www.facebook.com/yashika.pandey.18"
            target="_blank"
            rel="noopener noreferrer"
            className="facebook-icon" // Added specific class for Facebook icon
          >
            <FacebookOutlined style={{ fontSize: "2.5rem" }} />
          </a>
          <a
            href="https://www.instagram.com/ya_c_ka/"
            target="_blank"
            rel="noopener noreferrer">
            <InstagramOutlined style={{ fontSize: "2.5rem" }} />
          </a>
          <a
            href="https://www.linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-icon" // Added specific class for LinkedIn icon
          >
            <LinkedinOutlined style={{ fontSize: "2.5rem" }} />
          </a>
        </div>
        <div className="your-name">@ Yasika Pandey {currentYear}</div>
      </div>
    </Footer>
  );
};

export default AppFooter;

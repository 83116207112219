// src/CandleAnimation.js
import React from "react";
import "./CandleAnimation.css";

const CandleAnimation = () => {
  return (
    <div className="birthday-container">
      <div className="title-container">
        <h2 className="fade-in">Happy Birthday🎉 Yasika Pandey</h2>
      </div>
      <div className="cake-container">
        <div className="cake fade-in">
          <div className="icing"></div>
          <div className="candle">
            <div className="flame"></div>
            <div className="body"></div>
            <div className="wick"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandleAnimation;

import React from "react";
import "./AboutMe.css";

const AboutMe = () => {
  return (
    <div>
      <div className="about-container">
        <div className="about-heading">
          <h1 className="about-heading-text">About Me</h1>
          <div className="about-heading-line"></div>
        </div>
        <section className="section">
          <h2>Introduction</h2>
          <p>
            Hi I am Yasika Pandey. I am from Kathmandu, Nepal and I live in
            Granville, Sydney. I live with my family in a very nice environment.
          </p>
          <p>
            I was born on July 11, 2001 Wednesday (२०५८ असार २७) in Kathmandu. I
            did my schooling from there and I did my +2 from Trinity
            International College, Kalika Marg, Kathmandu.
          </p>
          <p>
            At the moment I am doing my Bachelors in Nursing from Western Sydney
            University. Also I am working in customer service at our own shop.
            Since my childhood I wanted to become a pilot, lawyer but I chose
            nursing because of job aspects. But in future I will probably
            establish my own consulting or real estate firm.
          </p>
          <p>
            With all the hard work that I am doing and all the things that I am
            learning from my Maternal Uncles here in Austrlia, I will become a
            very successful person soon.
          </p>
        </section>

        <section className="section">
          <h2>Where do you wanna travel?</h2>
          <p>
            There are lot of places that I want to visit, which are in my bucket
            list. First it will be to visit all the states of Australia, then I
            want to visit aborad as well.
          </p>
          <p>
            I want to visit Paris, France, New York, USA, London, UK, Dubai,
            UAE, and many more countries.
          </p>
          <p>
            Not to forget, I want to visit my home country Nepal and visit all
            the places that I haven't visited yet.
          </p>
        </section>

        <section className="section">
          <h2>More About Me</h2>
          <div className="faq-item">
            <h3 className="question">What are your favorite hobbies?</h3>
            <p>
              I love to spend time with my family, I love be around children.
              Also, I love to do shopping and try new outfits and go to new
              places.
            </p>
          </div>
          <div className="faq-item">
            <h3 className="question">What’s your dream job?</h3>
            <p>
              I think my dream job is to be a entrepreneur and establish my own
              successful company.
            </p>
          </div>

          <div className="faq-item">
            <h3 className="question">What are your favourite artist?</h3>
            <p>
              I like The Lumineers, Lana Del Rey, Billie Eilish, Stephen
              Sanchez, and many more.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutMe;
